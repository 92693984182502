import dice1 from "./dice1.svg";
import dice2 from "./dice2.svg";
import dice3 from "./dice3.svg";
import dice4 from "./dice4.svg";
import dice5 from "./dice5.svg";
import dice6 from "./dice6.svg";

const dices = [dice1, dice2, dice3, dice4, dice5, dice6];

export default dices;
