import "./InfoModal.css";
import React from "react";
import closeModal from "../../../assets/closeModal.svg";

const InfoModal = ({ infoModal, setInfoModal }) => {
	return (
		<div className={infoModal ? "info-modal show" : "info-modal hide"}>
			<img
				src={closeModal}
				style={{ width: 30, marginBottom: 10, cursor: "pointer" }}
				onClick={() => setInfoModal(false)}
			></img>
			<br></br>
			{"Bet with caution, code is open source and can be seen "}
			<a
				target="_blank"
				href="https://github.com/ergflip/backend"
				rel="noreferrer"
			>
				here
			</a>
			{", it has been reviewed by an Ergo Core Dev who helped me securing it"}
			<br></br>
			<br></br>
			{
				"There is a small fee (1:1.95 in games of 1/2 winning chance, and 1:5.8 in games of 1/6 winning chance and 1:9.7 in games of 1/10) that Grand Gambit keeps in order to to cover miner fees, hosting services (30$ month) and for development, lets keep the games coming!"
			}
			<br></br>
			<br></br>
			{
				"If you ever run into a bug such as the animation not starting after signing the tx or not stopping even after the transaction has been confirmed, please contact me on "
			}
			<a target="_blank" href="https:t.me/erg_flip" rel="noreferrer">
				telegram
			</a>
			{" or "}
			<a
				target="_blank"
				href="https://twitter.com/grand_gambit"
				rel="noreferrer"
			>
				twitter.
			</a>
			<br></br>
			{
				"iOS terminus wallet is not working, the site only works on Android for now."
			}
			<br></br>
			<br></br>
			{
				"This is not a decentralized application, there is no random number being generated by the blockchain, the server's public code does the randomness, feel free to analyze winnings and losses and check if it is indeed producing fair results if you don't trust. FYI there is a decentralized coinflip on ergo, but it can only be played once a day due to its true decentralization, that is not my desire for Grand Gambit."
			}
		</div>
	);
};

export default InfoModal;
