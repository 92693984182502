import ergIcon from "../../assets/ergo-icon.png";
import cometIcon from "../../assets/cometIcon.png";
import sigusdIcon from "../../assets/SigUSD.svg";
import cypxIcon from "../../assets/cypxIcon.svg";
import egioIcon from "../../assets/egioIcon.svg";
import fluxIcon from "../../assets/fluxIcon.svg";
import ergopadIcon from "../../assets/ergopadIcon.svg";
import paideiaIcon from "../../assets/paideiaIcon.svg";
import bassIcon from "../../assets/bassIcon.png";
import ergoneIcon from "../../assets/ergoneIcon.png";
import loveIcon from "../../assets/loveIcon.png";
import pepergIcon from "../../assets/pepergIcon.png";
import greasycexIcon from "../../assets/greasycexIcon.png";
import boberIcon from "../../assets/boberIcon.png";
import gifIcon from "../../assets/gifIcon.png";

const selectOptions2x = {
	ERG: [
		{ value: 1000000000, label: "1 ERG" },
		{ value: 2000000000, label: "2 ERG" },
		{ value: 3000000000, label: "3 ERG" },
		{ value: 4000000000, label: "4 ERG" },
		{ value: 5000000000, label: "5 ERG" },
		{ value: 6000000000, label: "6 ERG" },
		{ value: 7000000000, label: "7 ERG" },
		{ value: 8000000000, label: "8 ERG" },
		{ value: 9000000000, label: "9 ERG" },
		{ value: 10000000000, label: "10 ERG" },
		{ value: 11000000000, label: "11 ERG" },
		{ value: 12000000000, label: "12 ERG" },
		{ value: 13000000000, label: "13 ERG" },
		{ value: 14000000000, label: "14 ERG" },
		{ value: 15000000000, label: "15 ERG" },
		{ value: 16000000000, label: "16 ERG" },
		{ value: 17000000000, label: "17 ERG" },
		{ value: 18000000000, label: "18 ERG" },
		{ value: 19000000000, label: "19 ERG" },
		{ value: 20000000000, label: "20 ERG" },
		{ value: 21000000000, label: "21 ERG" },
		{ value: 22000000000, label: "22 ERG" },
		{ value: 23000000000, label: "23 ERG" },
	],
	SigUSD: [
		{ value: 100, label: "1 SigUSD" },
		{ value: 200, label: "2 SigUSD" },
		{ value: 300, label: "3 SigUSD" },
		{ value: 400, label: "4 SigUSD" },
		{ value: 500, label: "5 SigUSD" },
	],
	COMET: [
		{ value: 50000, label: "50k COMET" },
		{ value: 100000, label: "100k COMET" },
		{ value: 150000, label: "150k COMET" },
		{ value: 200000, label: "200k COMET" },
		{ value: 250000, label: "250k COMET" },
		{ value: 300000, label: "300k COMET" },
		{ value: 350000, label: "350k COMET" },
		{ value: 400000, label: "400k COMET" },
		{ value: 450000, label: "450k COMET" },
	],
	CYPX: [
		{ value: 4000000, label: "400 CYPX" },
		{ value: 5500000, label: "550 CYPX" },
		{ value: 7000000, label: "700 CYPX" },
		{ value: 8500000, label: "850 CYPX" },
		{ value: 10000000, label: "1000 CYPX" },
		{ value: 11500000, label: "1150 CYPX" },
		{ value: 13000000, label: "1300 CYPX" },
		{ value: 14500000, label: "1450 CYPX" },
		{ value: 16000000, label: "1600 CYPX" },
		{ value: 17500000, label: "1750 CYPX" },
		{ value: 19000000, label: "1900 CYPX" },
		{ value: 21500000, label: "2150 CYPX" },
		{ value: 23000000, label: "2300 CYPX" },
		{ value: 24500000, label: "2450 CYPX" },
	],
	EGIO: [
		{ value: 100000000, label: "10k EGIO" },
		{ value: 200000000, label: "20k EGIO" },
		{ value: 300000000, label: "30k EGIO" },
		{ value: 400000000, label: "40k EGIO" },
		{ value: 500000000, label: "50k EGIO" },
	],
	FLUX: [
		{ value: 100000000, label: "1 Flux" },
		{ value: 200000000, label: "2 Flux" },
		{ value: 300000000, label: "3 Flux" },
		{ value: 400000000, label: "4 Flux" },
		{ value: 500000000, label: "5 Flux" },
		{ value: 600000000, label: "6 Flux" },
		{ value: 700000000, label: "7 Flux" },
		{ value: 800000000, label: "8 Flux" },
	],
	EPAD: [
		{ value: 15000, label: "150 EPAD" },
		{ value: 30000, label: "300 EPAD" },
		{ value: 45000, label: "450 EPAD" },
		{ value: 60000, label: "600 EPAD" },
		{ value: 75000, label: "750 EPAD" },
		{ value: 90000, label: "900 EPAD" },
		{ value: 105000, label: "1050 EPAD" },
		{ value: 120000, label: "1200 EPAD" },
		{ value: 135000, label: "1350 EPAD" },
		{ value: 150000, label: "1500 EPAD" },
	],
	PAIDEIA: [
		{ value: 3000000, label: "300 PAIDEIA" },
		{ value: 6000000, label: "600 PAIDEIA" },
		{ value: 9000000, label: "900 PAIDEIA" },
		{ value: 12000000, label: "1200 PAIDEIA" },
		{ value: 15000000, label: "1500 PAIDEIA" },
		{ value: 18000000, label: "1800 PAIDEIA" },
		{ value: 21000000, label: "2100 PAIDEIA" },
		{ value: 24000000, label: "2400 PAIDEIA" },
		{ value: 27000000, label: "2700 PAIDEIA" },
		{ value: 30000000, label: "3000 PAIDEIA" },
	],
	BASS: [
		{ value: 1000000, label: "0.1 BASS" },
		{ value: 2000000, label: "0.2 BASS" },
		{ value: 3000000, label: "0.3 BASS" },
		{ value: 4000000, label: "0.4 BASS" },
		{ value: 5000000, label: "0.5 BASS" },
		{ value: 6000000, label: "0.6 BASS" },
		{ value: 7000000, label: "0.7 BASS" },
		{ value: 8000000, label: "0.8 BASS" },
		{ value: 9000000, label: "0.9 BASS" },
		{ value: 10000000, label: "1 BASS" },
		{ value: 11000000, label: "1.1 BASS" },
		{ value: 12000000, label: "1.2 BASS" },
		{ value: 13000000, label: "1.3 BASS" },
		{ value: 14000000, label: "1.4 BASS" },
		{ value: 15000000, label: "1.5 BASS" },
		{ value: 16000000, label: "1.6 BASS" },
		{ value: 17000000, label: "1.7 BASS" },
		{ value: 18000000, label: "1.8 BASS" },
		{ value: 19000000, label: "1.9 BASS" },
		{ value: 20000000, label: "2 BASS" },
	],
	ERGONE: [
		{ value: 1000000000, label: "10 ErgOne" },
		{ value: 1500000000, label: "15 ErgOne" },
		{ value: 2000000000, label: "20 ErgOne" },
		{ value: 2500000000, label: "25 ErgOne" },
		{ value: 3000000000, label: "30 ErgOne" },
		{ value: 3500000000, label: "35 ErgOne" },
		{ value: 4000000000, label: "40 ErgOne" },
		{ value: 4500000000, label: "45 ErgOne" },
		{ value: 5000000000, label: "50 ErgOne" },
	],
	LOVE: [
		{ value: 100, label: "100 love" },
		{ value: 200, label: "200 love" },
		{ value: 300, label: "300 love" },
		{ value: 400, label: "400 love" },
		{ value: 500, label: "500 love" },
		{ value: 600, label: "600 love" },
		{ value: 700, label: "700 love" },
		{ value: 800, label: "800 love" },
		{ value: 900, label: "900 love" },
		{ value: 1000, label: "1000 love" },
		{ value: 1250, label: "1250 love" },
		{ value: 1500, label: "1500 love" },
		{ value: 1750, label: "1750 love" },
		{ value: 2000, label: "2000 love" },
		{ value: 2250, label: "2250 love" },
		{ value: 2500, label: "2500 love" },
	],
	GREASYCEX: [
		{ value: 5000, label: "5000 GreasyCex" },
		{ value: 10000, label: "10000 GreasyCex" },
		{ value: 15000, label: "15000 GreasyCex" },
		{ value: 20000, label: "20000 GreasyCex" },
		{ value: 25000, label: "25000 GreasyCex" },
		{ value: 30000, label: "30000 GreasyCex" },
		{ value: 35000, label: "35000 GreasyCex" },
		{ value: 40000, label: "40000 GreasyCex" },
		{ value: 45000, label: "45000 GreasyCex" },
		{ value: 50000, label: "50000 GreasyCex" },
		{ value: 55000, label: "55000 GreasyCex" },
		{ value: 60000, label: "60000 GreasyCex" },
		{ value: 65000, label: "65000 GreasyCex" },
		{ value: 70000, label: "70000 GreasyCex" },
		{ value: 75000, label: "75000 GreasyCex" },
		{ value: 80000, label: "80000 GreasyCex" },
		{ value: 85000, label: "85000 GreasyCex" },
		{ value: 90000, label: "90000 GreasyCex" },
		{ value: 95000, label: "95000 GreasyCex" },
		{ value: 100000, label: "100000 GreasyCex" },
	],
	PEPERG: [
		{ value: 250, label: "250 PEPERG" },
		{ value: 500, label: "500 PEPERG" },
		{ value: 1000, label: "1k PEPERG" },
		{ value: 2000, label: "2k PEPERG" },
		{ value: 3000, label: "3k PEPERG" },
		{ value: 4000, label: "4k PEPERG" },
		{ value: 5000, label: "5k PEPERG" },
		{ value: 6000, label: "6k PEPERG" },
		{ value: 7000, label: "7k PEPERG" },
		{ value: 8000, label: "8k PEPERG" },
		{ value: 9000, label: "9k PEPERG" },
		{ value: 10000, label: "10k PEPERG" },
		{ value: 12500, label: "12.5k PEPERG" },
		{ value: 15000, label: "15k PEPERG" },
		{ value: 17500, label: "17.5k PEPERG" },
		{ value: 20000, label: "20k PEPERG" },
	],
	BOBER: [
		{ value: 1000, label: "1 BOBER" },
		{ value: 2000, label: "2 BOBER" },
		{ value: 3000, label: "3 BOBER" },
		{ value: 4000, label: "4 BOBER" },
		{ value: 5000, label: "5 BOBER" },
	],
	GIF: [
		{ value: 1000000000000, label: "1M GIF" },
		{ value: 2000000000000, label: "2M GIF" },
		{ value: 3000000000000, label: "3M GIF" },
		{ value: 4000000000000, label: "4M GIF" },
		{ value: 5000000000000, label: "5M GIF" },
		{ value: 10000000000000, label: "10M GIF" },
		{ value: 15000000000000, label: "15M GIF" },
		{ value: 20000000000000, label: "20M GIF" },
		{ value: 25000000000000, label: "25M GIF" },
		{ value: 30000000000000, label: "30M GIF" },
		{ value: 35000000000000, label: "35M GIF" },
		{ value: 40000000000000, label: "40M GIF" },
		{ value: 45000000000000, label: "45M GIF" },
		{ value: 50000000000000, label: "50M GIF" },
	],
};

const selectOptions2xReversed = {
	ERG: selectOptions2x.ERG.reverse(),
	SigUSD: selectOptions2x.SigUSD.reverse(),
	COMET: selectOptions2x.COMET.reverse(),
	CYPX: selectOptions2x.CYPX.reverse(),
	EGIO: selectOptions2x.EGIO.reverse(),
	FLUX: selectOptions2x.FLUX.reverse(),
	EPAD: selectOptions2x.EPAD.reverse(),
	PAIDEIA: selectOptions2x.PAIDEIA.reverse(),
	BASS: selectOptions2x.BASS.reverse(),
	ERGONE: selectOptions2x.ERGONE.reverse(),
	LOVE: selectOptions2x.LOVE.reverse(),
	GREASYCEX: selectOptions2x.GREASYCEX.reverse(),
	PEPERG: selectOptions2x.PEPERG.reverse(),
	BOBER: selectOptions2x.BOBER.reverse(),
	GIF: selectOptions2x.GIF.reverse(),
};

const selectOptions6x = {
	ERG: [
		{ value: 1000000000, label: "1 ERG" },
		{ value: 2000000000, label: "2 ERG" },
		{ value: 3000000000, label: "3 ERG" },
		{ value: 4000000000, label: "4 ERG" },
		{ value: 5000000000, label: "5 ERG" },
		{ value: 6000000000, label: "6 ERG" },
		{ value: 7000000000, label: "7 ERG" },
		{ value: 8000000000, label: "8 ERG" },
	],
	SigUSD: [
		{ value: 100, label: "1 SigUSD" },
		{ value: 200, label: "2 SigUSD" },
		{ value: 300, label: "3 SigUSD" },
	],
	COMET: [
		{ value: 50000, label: "50k COMET" },
		{ value: 100000, label: "100k COMET" },
		{ value: 150000, label: "150k COMET" },
		{ value: 200000, label: "200k COMET" },
		{ value: 250000, label: "250k COMET" },
	],
	CYPX: [
		{ value: 4000000, label: "400 CYPX" },
		{ value: 5500000, label: "550 CYPX" },
		{ value: 7000000, label: "700 CYPX" },
		{ value: 8500000, label: "850 CYPX" },
		{ value: 10000000, label: "1000 CYPX" },
		{ value: 11500000, label: "1150 CYPX" },
	],
	EGIO: [
		{ value: 100000000, label: "10k EGIO" },
		{ value: 200000000, label: "20k EGIO" },
		{ value: 300000000, label: "30k EGIO" },
	],
	FLUX: [
		{ value: 100000000, label: "1 Flux" },
		{ value: 200000000, label: "2 Flux" },
		{ value: 300000000, label: "3 Flux" },
		{ value: 400000000, label: "4 Flux" },
	],
	EPAD: [
		{ value: 15000, label: "150 EPAD" },
		{ value: 30000, label: "300 EPAD" },
		{ value: 45000, label: "450 EPAD" },
		{ value: 60000, label: "600 EPAD" },
		{ value: 75000, label: "750 EPAD" },
	],
	PAIDEIA: [
		{ value: 3000000, label: "300 PAIDEIA" },
		{ value: 6000000, label: "600 PAIDEIA" },
		{ value: 9000000, label: "900 PAIDEIA" },
		{ value: 12000000, label: "1200 PAIDEIA" },
	],
	BASS: [
		{ value: 1000000, label: "0.1 BASS" },
		{ value: 2000000, label: "0.2 BASS" },
		{ value: 3000000, label: "0.3 BASS" },
		{ value: 4000000, label: "0.4 BASS" },
		{ value: 5000000, label: "0.5 BASS" },
	],
	ERGONE: [
		{ value: 1000000000, label: "10 ErgOne" },
		{ value: 1500000000, label: "15 ErgOne" },
		{ value: 2000000000, label: "20 ErgOne" },
		{ value: 2500000000, label: "25 ErgOne" },
	],
	LOVE: [
		{ value: 100, label: "100 love" },
		{ value: 200, label: "200 love" },
		{ value: 300, label: "300 love" },
		{ value: 400, label: "400 love" },
		{ value: 500, label: "500 love" },
		{ value: 600, label: "600 love" },
		{ value: 700, label: "700 love" },
	],
	GREASYCEX: [
		{ value: 5000, label: "5000 GreasyCex" },
		{ value: 10000, label: "10000 GreasyCex" },
		{ value: 15000, label: "15000 GreasyCex" },
		{ value: 20000, label: "20000 GreasyCex" },
		{ value: 25000, label: "25000 GreasyCex" },
		{ value: 30000, label: "30000 GreasyCex" },
		{ value: 35000, label: "35000 GreasyCex" },
		{ value: 40000, label: "40000 GreasyCex" },
	],
	PEPERG: [
		{ value: 250, label: "250 PEPERG" },
		{ value: 500, label: "500 PEPERG" },
		{ value: 1000, label: "1k PEPERG" },
		{ value: 2000, label: "2k PEPERG" },
		{ value: 3000, label: "3k PEPERG" },
		{ value: 4000, label: "4k PEPERG" },
		{ value: 5000, label: "5k PEPERG" },
		{ value: 6000, label: "6k PEPERG" },
		{ value: 7000, label: "7k PEPERG" },
		{ value: 8000, label: "8k PEPERG" },
		{ value: 9000, label: "9k PEPERG" },
		{ value: 10000, label: "10k PEPERG" },
	],
	BOBER: [
		{ value: 1000, label: "1 BOBER" },
		{ value: 2000, label: "2 BOBER" },
		{ value: 3000, label: "3 BOBER" },
		{ value: 4000, label: "4 BOBER" },
	],
	GIF: [
		{ value: 1000000000000, label: "1M GIF" },
		{ value: 2000000000000, label: "2M GIF" },
		{ value: 3000000000000, label: "3M GIF" },
		{ value: 4000000000000, label: "4M GIF" },
		{ value: 5000000000000, label: "5M GIF" },
		{ value: 10000000000000, label: "10M GIF" },
		{ value: 15000000000000, label: "15M GIF" },
		{ value: 20000000000000, label: "20M GIF" },
		{ value: 25000000000000, label: "25M GIF" },
	],
};

const selectOptions6xReversed = {
	ERG: selectOptions6x.ERG.reverse(),
	SigUSD: selectOptions6x.SigUSD.reverse(),
	COMET: selectOptions6x.COMET.reverse(),
	CYPX: selectOptions6x.CYPX.reverse(),
	EGIO: selectOptions6x.EGIO.reverse(),
	FLUX: selectOptions6x.FLUX.reverse(),
	EPAD: selectOptions6x.EPAD.reverse(),
	PAIDEIA: selectOptions6x.PAIDEIA.reverse(),
	BASS: selectOptions6x.BASS.reverse(),
	ERGONE: selectOptions6x.ERGONE.reverse(),
	LOVE: selectOptions6x.LOVE.reverse(),
	GREASYCEX: selectOptions6x.GREASYCEX.reverse(),
	PEPERG: selectOptions6x.PEPERG.reverse(),
	BOBER: selectOptions6x.BOBER.reverse(),
	GIF: selectOptions6x.GIF.reverse(),
};

const selectOptions10x = {
	ERG: [
		{ value: 1000000000, label: "1 ERG" },
		{ value: 2000000000, label: "2 ERG" },
		{ value: 3000000000, label: "3 ERG" },
		{ value: 4000000000, label: "4 ERG" },
		{ value: 5000000000, label: "5 ERG" },
	],
	SigUSD: [
		{ value: 100, label: "1 SigUSD" },
		{ value: 200, label: "2 SigUSD" },
	],
	COMET: [
		{ value: 50000, label: "50k COMET" },
		{ value: 100000, label: "100k COMET" },
		{ value: 150000, label: "150k COMET" },
	],
	CYPX: [
		{ value: 500000, label: "50 CYPX" },
		{ value: 2000000, label: "200 CYPX" },
		{ value: 3500000, label: "350 CYPX" },
		{ value: 5000000, label: "500 CYPX" },
		{ value: 7500000, label: "750 CYPX" },
	],
	EGIO: [
		{ value: 100000000, label: "10k EGIO" },
		{ value: 200000000, label: "20k EGIO" },
	],
	FLUX: [
		{ value: 100000000, label: "1 Flux" },
		{ value: 200000000, label: "2 Flux" },
		{ value: 300000000, label: "3 Flux" },
	],
	EPAD: [
		{ value: 10000, label: "100 EPAD" },
		{ value: 20000, label: "200 EPAD" },
		{ value: 30000, label: "300 EPAD" },
		{ value: 40000, label: "400 EPAD" },
		{ value: 50000, label: "500 EPAD" },
	],
	PAIDEIA: [
		{ value: 1500000, label: "150 PAIDEIA" },
		{ value: 3000000, label: "300 PAIDEIA" },
		{ value: 4500000, label: "450 PAIDEIA" },
		{ value: 6000000, label: "600 PAIDEIA" },
		{ value: 7500000, label: "750 PAIDEIA" },
	],
	BASS: [
		{ value: 1000000, label: "0.1 BASS" },
		{ value: 2000000, label: "0.2 BASS" },
		{ value: 3000000, label: "0.3 BASS" },
		{ value: 4000000, label: "0.4 BASS" },
	],
	ERGONE: [
		{ value: 500000000, label: "5 ErgOne" },
		{ value: 1000000000, label: "10 ErgOne" },
		{ value: 1500000000, label: "15 ErgOne" },
	],
	LOVE: [
		{ value: 100, label: "100 love" },
		{ value: 200, label: "200 love" },
		{ value: 300, label: "300 love" },
	],
	GREASYCEX: [
		{ value: 5000, label: "5000 GreasyCex" },
		{ value: 10000, label: "10000 GreasyCex" },
		{ value: 15000, label: "15000 GreasyCex" },
	],
	PEPERG: [
		{ value: 250, label: "250 PEPERG" },
		{ value: 500, label: "500 PEPERG" },
		{ value: 1000, label: "1k PEPERG" },
		{ value: 1500, label: "1.5k PEPERG" },
		{ value: 2000, label: "2k PEPERG" },
		{ value: 2500, label: "2.5k PEPERG" },
		{ value: 3000, label: "3k PEPERG" },
		{ value: 4000, label: "4k PEPERG" },
		{ value: 5000, label: "5k PEPERG" },
		{ value: 6000, label: "6k PEPERG" },
	],
	BOBER: [
		{ value: 1000, label: "1 BOBER" },
		{ value: 2000, label: "2 BOBER" },
		{ value: 3000, label: "3 BOBER" },
	],
	GIF: [
		{ value: 1000000000000, label: "1M GIF" },
		{ value: 2000000000000, label: "2M GIF" },
		{ value: 3000000000000, label: "3M GIF" },
		{ value: 4000000000000, label: "4M GIF" },
		{ value: 5000000000000, label: "5M GIF" },
		{ value: 10000000000000, label: "10M GIF" },
		{ value: 15000000000000, label: "15M GIF" },
	],
};

const selectOptions10xReversed = {
	ERG: selectOptions10x.ERG.reverse(),
	SigUSD: selectOptions10x.SigUSD.reverse(),
	COMET: selectOptions10x.COMET.reverse(),
	CYPX: selectOptions10x.CYPX.reverse(),
	EGIO: selectOptions10x.EGIO.reverse(),
	FLUX: selectOptions10x.FLUX.reverse(),
	EPAD: selectOptions10x.EPAD.reverse(),
	PAIDEIA: selectOptions10x.PAIDEIA.reverse(),
	BASS: selectOptions10x.BASS.reverse(),
	ERGONE: selectOptions10x.ERGONE.reverse(),
	LOVE: selectOptions10x.LOVE.reverse(),
	GREASYCEX: selectOptions10x.GREASYCEX.reverse(),
	PEPERG: selectOptions10x.PEPERG.reverse(),
	BOBER: selectOptions10x.BOBER.reverse(),
	GIF: selectOptions10x.GIF.reverse(),
};

const tokenOptions = [
	{ value: "ERG", label: "ERG", img: <img src={ergIcon} width={30}></img> },
	{ value: "GIF", label: "GIF", img: <img src={gifIcon} width={30}></img> },
	{ value: "BOBER", label: "BOBER", img: <img src={boberIcon} width={30}></img> },
	{
		value: "PEPERG",
		label: "PEPERG",
		img: <img src={pepergIcon} width={30}></img>,
	},
	{ value: "BASS", label: "BASS", img: <img src={bassIcon} width={30}></img> },
	{
		value: "GREASYCEX",
		label: "GreasyCex",
		img: <img src={greasycexIcon} width={30}></img>,
	},
	{
		value: "ERGONE",
		label: "ErgOne",
		img: <img src={ergoneIcon} width={30}></img>,
	},
	{
		value: "SigUSD",
		label: "SigUSD",
		img: <img src={sigusdIcon} width={30}></img>,
	},
	{
		value: "COMET",
		label: "COMET",
		img: <img src={cometIcon} width={30}></img>,
	},
	{ value: "CYPX", label: "CYPX", img: <img src={cypxIcon} width={30}></img> },
	{
		value: "PAIDEIA",
		label: "Paideia",
		img: <img src={paideiaIcon} width={30}></img>,
	},
	{
		value: "EPAD",
		label: "ErgoPad",
		img: <img src={ergopadIcon} width={30}></img>,
	},
	{ value: "FLUX", label: "FLUX", img: <img src={fluxIcon} width={30}></img> },

	{ value: "EGIO", label: "EGIO", img: <img src={egioIcon} width={30}></img> },

	// { value: "LOVE", label: "love", img: <img src={loveIcon} width={30}></img> },
];

const customStyles = {
	option: (provided, state) => ({
		...provided,
		color: "black",
		backgroundColor: state.isFocused ? "#91e1f9" : "white",
		fontWeight: "bold",
	}),
	container: (provided) => ({
		...provided,
		"@media only screen and (max-width: 600px)": {
			...provided["@media only screen and (max-width: 600px)"],
			fontSize: "0.9em",
		},
	}),
};

export {
	// selectOptions2x,
	tokenOptions,
	customStyles,
	// selectOptions6x,
	// selectOptions10x,
	selectOptions2xReversed,
	selectOptions6xReversed,
	selectOptions10xReversed,
};
